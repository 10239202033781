import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import FacilityAdd from "@components/facilities/Add"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "facilities.create"
}

const FacilityAddPage = (props) => {
  return (
    <>
      <Seo title="Add Facility" />
      <div className="main-wrapper">
        <Sidebar menuActive="settings"
          action="Add" />
        <FacilityAdd
          menu="Add Facilities"
          parentPath="facilities"
          pageType="Add"
          additionalParams={AdditionalParams} />
      </div>
    </>
  )
}

export default FacilityAddPage;
