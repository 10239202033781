import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  HandleChange, CheckFieldValidity,
  ResetFormValuesById,
} from "../../utility/Form";
import CommonAddItemComp from "../common/Add";
import FormFooter from "../common/FormFooter"
import { get } from "lodash";

const AddFormControls = (props) => {
  const [formError, setFromError] = useState({});
  const CheckFormFieldValidity = (event) => {
    const FieldError = CheckFieldValidity(event);
    setFromError({ ...formError, ...FieldError });
  }

  const HandleChangeFunc = (event) => {
    HandleChange(event);
    CheckFormFieldValidity(event)
  }

  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'add-facility-form',
        ...additionalParams
      });
      if (get(Result, 'formSubmit.hasError')) {
        setFromError(Result.formSubmit.error);
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  const ResetForm = () => {
    ResetFormValuesById('add-facility-form');
  }

  return (
    <>
      <Form id="add-facility-form" noValidate onSubmit={(event) => FormSubmitFunc(event, {
        isSubmit: true,
        isPageRedirect: true,
        publish: true,
        parentPath: props.parentPath,
        pageRedirectUrl: `/${props.parentPath}/list`
      })}>
        <Row className="my-4">
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Facility *</label>
              <input type="text" name="facility" className={`form-control${formError?.facility?.hasError ? ' pu-has-control-error' : ''}`}
                onChange={(event) => HandleChangeFunc(event)}
                required={true}
                placeholder="Enter facility here" />
              {
                ((formError?.facility?.hasError && (formError?.facility?.type === 'required')) && <div className="pu-has-error">
                  Facility is required!
                </div>)
              }
            </div>
          </Col>
        </Row>
        <FormFooter parentPath={props.parentPath} resetForm={ResetForm} handleSave={FormSubmitFunc}></FormFooter>
      </Form>
    </>
  )
}

const AddFacility = (props) => {
  return <CommonAddItemComp PuFormControlsAdd={
    (HandleSubmitFunc) => {
      return (
        <>
          <AddFormControls HandleSubmitFunc={HandleSubmitFunc} {...props} />
        </>
      )
    }
  } {...props} />
}

export default AddFacility;